import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from '../../shared/guards/login.guard';

const routes: Routes = [
  {
    path: "calendar",
    canActivate: [LoginGuard],
    loadComponent: () => import('./pages/pms-calendar/pms-calendar.component').then(m => m.PmsCalendarComponent),
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CalendarRoutingModule {}
