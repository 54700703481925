<section
    *ngIf="appointmentDetails"
    class="appointment-card-holder shadow-md mt-2 joii-border-primary rounded overflow-hidden relative dark:bg-slate-800 dark:border-blue-500"
    [class.border-l-4]="expanded"
>
    <div class="absolute top-6 right-7 w-auto">
        <select
            [(ngModel)]="selectedStaff"
            (change)="handleStaffSelect($event)"
            [disabled]="appointmentDetails.consult_status || false"
            class="bg-gray-50 dark:bg-slate-800 border-gray-300 text-gray-900 dark:text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 pr-7"
        >
            <option
                *ngFor="let staffMember of availableStaff"
                [value]="staffMember.id"
                [title]="staffMember.name"
            >
                {{ staffMember.name | staffName }}
            </option>
            <!--<option [value]="'un-assign'" *ngIf="appointmentDetails.staff_profile_id">Unassign</option>-->
        </select>
    </div>
    <article class="p-5 bg-white dark:bg-slate-800" (click)="toggleExpanded()">
        <div class="profile-image-holder mr-2 rounded-full overflow-hidden">
            <span
                *ngIf="appointmentDetails.pet.photo_url"
                class="profile-image"
            >
                <img
                    [src]="appointmentDetails.pet.photo_url"
                    [alt]="appointmentDetails.pet.name"
                />
            </span>
            <span
                *ngIf="
                    !appointmentDetails.pet.photo_url &&
                    appointmentDetails.pet.name
                "
                class="ring-2 joii-ring-danger-mid joii-bg-danger-light joii-text-danger-dark w-full h-full inline-block text-center align-middle text-2xl leading-9 rounded-full"
            >
                {{ appointmentDetails.pet.name[0] }}
            </span>
        </div>
        <div class="appointment-details-header align-top">
            <p class="dark:text-stone-200">
                <span
                    class="h-5 pl-2 justify-start items-start inline-flex mr-1"
                >
                    <div
                        class="px-1 py-0.5 bg-yellow-100 rounded justify-center items-center gap-1 flex"
                        [class.bg-green-100]="!isHealthCheck"
                        [class.bg-yellow-100]="isHealthCheck"
                    >
                        <div
                            class="text-right text-slate-800 text-xs font-medium tracking-tight"
                            *ngIf="!isHealthCheck"
                        >
                            {{ appointmentDetails.service_name }}
                        </div>
                        <div
                            class="text-right text-slate-800 text-xs font-medium tracking-tight"
                            *ngIf="isHealthCheck"
                        >
                            AHC
                        </div>
                    </div>
                </span>
                <strong>{{ appointmentDetails.on_demand ? "on-demand" : "booked" }} </strong>
                <span *ngIf="appointmentDetails.user.first_name"
                    >{{ appointmentDetails.user.first_name }}
                    {{ appointmentDetails.user.last_name }}</span
                >
                <span>&nbsp;calling for&nbsp;</span>
                <span *ngIf="appointmentDetails.pet.name">{{
                    appointmentDetails.pet.name
                }}</span>
                <span *ngIf="!expanded" class="warnings-holder ml-2">
                    <span
                        *ngIf="
                            appointmentDetails.symptom_checker_session_outcome_urgency ===
                            'high'
                        "
                        class="bg-red-600 warning-icon"
                    ></span>
                    <app-prescribing-eligibility-pill
                        [postcode]="appointmentDetails.user.post_code"
                        [tinyMode]="true"
                    ></app-prescribing-eligibility-pill>
                </span>
                <span *ngIf="appointmentDetails.pet.policy_active">
                    @switch (appointmentDetails.business.partner_integration) {
                        @case ("ANIMALFRIENDS") {
                            <span class="w-5 h-5 ml-1 inline-block">
                                <img
                                    src="https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FAFI_icon.png?alt=media&token=87f113af-06d5-48c2-bc2c-7a98bf925afd"
                                    class="w-full h-auto"
                                    title="AFI"
                                />
                            </span>
                        }
                        @case ("ASDA") {
                            <span class="w-5 h-5 ml-1 inline-block">
                                <img
                                    src="https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FASDA_icon.png?alt=media&token=960ed7ad-5e44-4b71-9c32-00e5e085eb9c"
                                    class="w-full h-auto"
                                    title="Asda"
                                />
                            </span>
                        }
                        @case ("PURELYPETS") {
                            <span class="w-5 h-5 ml-1 inline-block">
                                <img
                                    src="https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FPurelyPets_icon.png?alt=media&token=0f5a8133-d362-49e5-a5a0-14585ad33e36"
                                    class="w-full h-auto"
                                    title="Purely Pets"
                                />
                            </span>
                        }
                        @case ("WAGGLE") {
                            <span class="w-5 h-5 ml-1 inline-block">
                                <img
                                    src="https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FWaggel_icon.png?alt=media&token=f68f5688-49dd-44af-851d-9fb4c470f725"
                                    class="w-full h-auto"
                                    title="Waggle"
                                />
                            </span>
                        }
                        @default {
                            <span class="inline-block">{{
                                appointmentDetails.business.name
                            }}</span>
                        }
                    }
                </span>
            </p>
            <p>
                <lucide-angular
                    class="inline-block mr-0.5 relative -top-5-px"
                    name="clock"
                    size="15"
                    strokeWidth="3"
                ></lucide-angular>
                <strong>
                    @if(appointmentDetails.start.time_friendly) {
                      {{appointmentDetails.start.time_friendly}}
                    }
                    &nbsp;|&nbsp;Wait time:
                    @if (
                        appointmentDetails.waiting_time_in_minutes === undefined
                    ) {
                        pending
                    } @else {
                        {{
                            appointmentDetails.waiting_time_in_minutes
                                | minutesToHours
                        }}
                    }
                </strong>
            </p>
            <p *ngIf="appointmentDetails.pet.triage">
                <span class="text-right bg-cool-blue-500 rounded px-1 py-0.5 text-xs font-medium tracking-tight text-slate-950">Waggel Triage</span>
            </p>
            <p
                *ngIf="
                    appointmentDetails.symptom_checker_session_algorithm_title &&
                    !expanded
                "
                class="mb-2"
            >
                {{ appointmentDetails.symptom_checker_session_algorithm_title }}
            </p>
        </div>
    </article>
    <article *ngIf="expanded" class="pl-5 pr-5 pb-5 bg-white dark:bg-slate-800">
        <hr />
        <section class="mt-2">
            <p class="joii-text-primary capitalize">
                <span *ngIf="appointmentDetails.pet.species"
                    >{{ appointmentDetails.pet.species }},&nbsp;</span
                >
                <span *ngIf="appointmentDetails.pet.gender"
                    >{{ appointmentDetails.pet.gender }}
                    <span *ngIf="appointmentDetails.pet.neutered"
                        >&nbsp;(neutered)</span
                    >,&nbsp;
                </span>
                <span *ngIf="appointmentDetails.pet.breed">
                    <span>{{ appointmentDetails.pet.breed.name }}</span
                    >,&nbsp;
                </span>
                <span *ngIf="appointmentDetails.pet.age"
                    >Age: {{ appointmentDetails.pet.age.years }} year<span
                        *ngIf="appointmentDetails.pet.age.years > 1"
                        >s</span
                    >
                    <span *ngIf="appointmentDetails.pet.age.months > 0"
                        >, {{ appointmentDetails.pet.age.months }} month<span
                            *ngIf="appointmentDetails.pet.age.months > 1"
                            >s</span
                        ></span
                    >
                    <span *ngIf="appointmentDetails.pet.weight"
                        >,&nbsp;</span
                    ></span
                >

                <span *ngIf="appointmentDetails.pet.weight"
                    ><!--TODO:change-->{{
                        appointmentDetails.pet.weight.kg
                    }}kg</span
                >
            </p>
            <p
                *ngIf="
                    appointmentDetails.symptom_checker_session_algorithm_title
                "
                class="mb-2"
            >
                {{ appointmentDetails.symptom_checker_session_algorithm_title }}
            </p>
            <span
                *ngIf="
                    appointmentDetails.symptom_checker_session_outcome_urgency ===
                    'high'
                "
                class="bg-red-600 text-white text-sm p-1 pl-3 pr-3 mr-2 rounded-xl"
                >Urgent Call</span
            >
            <app-prescribing-eligibility-pill
                [postcode]="appointmentDetails.user.post_code"
            ></app-prescribing-eligibility-pill>
        </section>
        <footer class="text-right mt-2">
            @if (appointmentDetails.is_overdue) {
              <button
                class="z-10 px-4 py-3.5 rounded-lg justify-center items-center gap-2.5 inline-flex text-center joii-text-primary text-[15px] font-semibold font-['Inter'] tracking-tight"
                data-automationid="appointment-card-terminate-btn"
                (click)="handleTerminateAppointment(appointmentDetails)"
              >
                Terminate
              </button>
            }
            <button
                class="z-10 px-4 py-3.5 rounded-lg justify-center items-center gap-2.5 inline-flex text-center joii-text-primary text-[15px] font-semibold font-['Inter'] tracking-tight"
                data-automationid="appointment-card-more-details-btn"
                (click)="moreDetails()"
            >
                More Details
            </button>

            @if (appointmentDetails.consult_status) {
                <button
                    class="joii-btn-primary"
                    [disabled]="
                        appointmentDetails.staff_profile_id != staffProfile.id || loadingCreateConsult
                    "
                    data-automationid="appointment-card-continue-call-btn"
                    (click)="
                        handleContinueConsultation(appointmentDetails.doc_id)
                    "
                >
                    Continue consult
                </button>
            } @else {
                <button
                    class="joii-btn-primary"
                    [disabled]="
                        appointmentDetails.staff_profile_id !=
                            staffProfile.id || loadingCreateConsult
                    "
                    data-automationid="appointment-card-start-call-btn"
                    (click)="
                        handleStartConsultation(
                            appointmentDetails.pet.doc_id,
                            appointmentDetails.user.doc_id,
                            appointmentDetails.doc_id
                        )
                    "
                >
                    Start consult
                </button>
            }
        </footer>
    </article>
</section>
