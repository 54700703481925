import { inject, Injectable } from "@angular/core";
import { Loader } from "@googlemaps/js-api-loader"
import { Observable, from, map, shareReplay, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { consultationinternal } from "../client/client";
import { ClientService } from "../client/client.service";

@Injectable({
    providedIn: 'root'
})
export class GooglePlacesService {
    private firebaseLoader = new Loader({ apiKey: environment.firebase.apiKey, version: "weekly" });
    private placesService = from(this.firebaseLoader.importLibrary('places')).pipe(map(lib => new lib.PlacesService(document.createElement('div'))), shareReplay());

    public GetVetsForAddress(address: string): Observable<searchResult> {
        return this.placesService.pipe(
            switchMap((places) =>
                new Observable<searchResult>(observer => {
                    places?.textSearch(
                        {
                            query: address,
                            type: 'veterinary_care',
                        },
                        (resp, status) => {
                            if (status === 'OK') {
                                const filteredResults = resp?.filter(place =>
                                    place.types?.includes("veterinary_care")
                                ) || [];
                                observer.next({
                                    results: filteredResults,
                                    paging: null
                                });
                            } else {
                                observer.error(status);
                            }
                        }
                    );
                })
            )
        );
    }

    clientService = inject(ClientService);

    public getPartnerPracticeWithin25Miles(address: string): Observable<consultationinternal.CheckPostcodeEligibilityResponse> {
        return this.clientService.client.pipe(switchMap(client => client.consultationinternal.CheckPostcodeEligibility({ Postcode: address })))
    }
}

export interface searchResult {
    results: google.maps.places.PlaceResult[] | null,
    paging: google.maps.places.PlaceSearchPagination | null
}


