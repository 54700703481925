<div class="relative" [class.open]="isDropdownOpen">
  <app-label [label]="label"></app-label>
  <input
    #dropdownInput
    type="text"
    [(ngModel)]="searchText"
    (input)="filterOptions()"
    (click)="toggleDropdown()"
    placeholder="Type to search..."
    class="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm  placeholder:font-normal focus:ring focus:ring-indigo-300 focus:border-indigo-300 sm:text-sm dark:bg-slate-700"
  />

  <div class="flex flex-wrap gap-2 pt-2 pb-2" *ngIf="selectedValues && selectedValues.length > 0">
    <span class="joii-bg-primary text-white py-1 px-2 rounded" *ngFor="let selectedOption of selectedValues">
      {{getOptionName(selectedOption)}}
      <button type="button" (click)="removeSelectedOption(selectedOption); $event.stopPropagation();" class="ml-2 text-sm">&times;</button>
    </span>
  </div>

  <div
    class="absolute z-10 mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg dark:bg-slate-700"
    *ngIf="isDropdownOpen"
  >
    <div class="max-h-36 overflow-y-auto">
      <div
        class="py-2 px-4 cursor-pointer hover:bg-indigo-100 font-normal flex items-center justify-between"
        *ngFor="let option of filteredOptions"
        (click)="toggleOption(option.value)"
      >
         {{ option.name }}
        <span *ngIf="isSelected(option.value)" class="mr-2"
          ><svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="12" fill="#4AB8B9" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.6094 8.25L10.6875 13.2L8.39062 10.89L6.75 12.54L10.6875 16.5L17.25 9.9L15.6094 8.25Z"
              fill="white"
            />
          </svg>
        </span>

      </div>
    </div>
  </div>
</div>
