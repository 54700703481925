@if(partnerPractice()?.is_eligible){
@if (tinyMode()) {
<div class="w-2 h-2 bg-cool-blue rounded-full inline-block" [ngClass]="backgroundStyleClasses()"></div>
} @else {
<div class="justify-start items-start inline-flex h-7">
    <div class="p-1.5 bg-cool-blue rounded-[999px] justify-start items-center gap-px flex"
        [ngClass]="backgroundStyleClasses()">
        <div class="px-1 justify-start items-center gap-[7px] flex">
            <div class="text-white text-xs leading-none tracking-tight" [ngClass]="textStyleClasses()">Eligible for
                prescriptions</div>
        </div>
    </div>
</div>
}
}